<template>

    <div class="training">

        <div class="training-data gr-16 gr-15@small gr-14@tablet gr-20@mobile">

            <h1>{{ training.title }}</h1>
            <p>Intensivtraining mit

                <span v-for="(coach, index) in coaches" :key="index" class="training-data-coach">
                     {{ index > 0? ' und' : '' }}
                    <router-link :to="{ name: 'coach', params: { coachSlug: coach.attributes.coachSlug } }">
                       {{ coach.attributes.firstName }} {{ coach.attributes.lastName }}
                    </router-link>
                </span>

            </p>

        </div>
      <div v-if="displayModules" class="gr-24 mt-1 mb-2">
      <base-button :to="{ name: 'portalOverview' }" class="button" link>
        <img src="../../images/back-arrow.svg" width="24" height="24"/>
      </base-button>
      </div>

        <transition name="fade">
            <router-view :authData="authData" :user="user" :training="training" :contact="contact"
                         :videoIsPlaying="videoIsPlaying" class="gr-24"></router-view>
        </transition>

        <transition name="fade">
            <div v-if="displayModules">

              <h2 class="gr-20 push-2 push-2@small mb-1">Willkommen zurück!</h2>
                <div class="training-last-position gr-24" v-if="lastPositionModule">

                    <div class="training-last-position-module gr-20 gr-20@tablet gr-24@mobile push-2 push-1@small push-2@tablet push-0@mobile">

                        <ModulesOverview :module="lastPositionModule" @move-to-last-Position="moveToLastPosition"
                                         lastPosition="true"></ModulesOverview>

                    </div>

                </div>

                <div class="training-modules gr-24">

                    <h1 class="gr-20 push-3 push-2@small">Inhalt</h1>

                    <div v-for="(module, index) in training.modules" :key="index"
                         class="training-modules-module gr-20 gr-20@tablet gr-24@mobile push-2 push-2@tablet push-0@mobile">

                        <ModulesOverview :module="module" @move-to-module="moveToModule"></ModulesOverview>

                    </div>

                </div>

            </div>
        </transition>

      <div class="gr-24 mt-1">
        <base-button :to="{ name: 'portalOverview' }" class="button" link>Zurück zum Portal</base-button>
      </div>

    </div>

</template>

<script>
    import { defineAsyncComponent } from 'vue';
    const ModulesOverview = defineAsyncComponent(() => import('./ModulesOverview.vue'));

    export default {
        props: ['authData', 'content', 'user', 'coaches'],
        components: {
            ModulesOverview
        },
        data() {
            return {
                displayModules: true,
                displayNotes: {},
            }
        },
        watch: {
            $route(curVal) {
                if(curVal.params.moduleSlug) {
                    this.displayModules = false;
                } else {
                    this.displayModules = true;
                }
            }
        },
        methods: {
            moveToLastPosition() {
                this.$router.push({ name: 'unit', params: { moduleSlug: this.lastPosition.module.slug, unitSlug: this.lastPosition.module.unit.slug } });
            },
            moveToModule(slug) {
                this.$router.push({ name: 'module', params: { moduleSlug: slug } });
            }
        },
        computed: {
            lastPosition() {
                return this.user.lastPosition && this.user.lastPosition[this.training.slug]? this.user.lastPosition[this.training.slug] : false;
            },
            lastPositionModule() {
                let modules = this.training.modules || false;

                let lastPositionModule = false;

                if(this.lastPosition) {
                    lastPositionModule = modules.filter(module => module.slug === this.lastPosition.module.slug)[0];
                }

                return lastPositionModule;
            },
            training() {
                let training = this.content;
                training.modules?.sort((a, b) => a.number - b.number);

                for(let key in training.modules) {
                    training.modules[key].units.sort((a, b) => a.number - b.number);
                }

                return training;
            }
        }
    }
</script>


<style scoped>
</style>
